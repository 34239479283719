import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { OutlinedInput, FormHelperText, Stack, Typography, Link } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import fakeRequest from '../../../utils/fakeRequest';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

// eslint-disable-next-line consistent-return
function maxLength(object) {
  if (object.target.value.length > object.target.maxLength) {
    return (object.target.value = object.target.value.slice(0, object.target.maxLength));
  }
}

export default function VerifyCodeForm({ submittedEmail, setWelcome }) {
  const [countdown, setCountdown] = useState(10 * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.number().required('Code is required'),
    code2: Yup.number().required('Code is required'),
    code3: Yup.number().required('Code is required'),
    code4: Yup.number().required('Code is required'),
    code5: Yup.number().required('Code is required'),
    code6: Yup.number().required('Code is required'),
  });

  const formik = useFormik({
    initialValues: {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
    },
    validationSchema: VerifyCodeSchema,
    onSubmit: async (values, { resetForm }) => {
      await fakeRequest(500);
      enqueueSnackbar('Verify success', { variant: 'success' });
      resetForm();
      setWelcome(true);
    },
  });

  function handleWelcomeOpen() {
    const hasEnteredCode = Object.values(formik.values).every((code) => String(code).trim().length > 0);

    if (hasEnteredCode) {
      enqueueSnackbar('Verify success', { variant: 'success' });
      setWelcome(true);
      console.log('Form Code :',values);
    } else {
      enqueueSnackbar('Please enter all code digits', { variant: 'error' });
    }
  }

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  const handlePaste = (e, fieldName) => {
    const pastedValue = e.clipboardData.getData('text').replace(/\D/g, '');
    const formattedValue = pastedValue.slice(0, 6);
    const fieldValues = formattedValue.split('').reduce((acc, char, index) => {
      acc[`code${index + 1}`] = char;
      return acc;
    }, {});
    formik.setValues({ ...formik.values, ...fieldValues });
  };
  

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} justifyContent="center">
          {Object.keys(values).map((item) => (
            <OutlinedInput
              key={item}
              {...getFieldProps(item)}
              type="number"
              placeholder="-"
              onInput={maxLength}
              error={Boolean(touched[item] && errors[item])}
              inputProps={{
                maxLength: 1,
                sx: {
                  p: 0,
                  textAlign: 'center',
                  width: { xs: 36, sm: 56 },
                  height: { xs: 36, sm: 56 },
                },
                onPaste: (e) => handlePaste(e, item),
              }}
            />
          ))}
        </Stack>

        <FormHelperText error={!formik.isValid && formik.submitCount > 0} style={{ textAlign: 'right' }}>
          {!formik.isValid && formik.submitCount > 0 && 'Please enter all code digits'}
        </FormHelperText>
        <Typography variant="body1" sx={{ fontSize: '13px', mt: 3, display: 'flex', justifyContent: 'center' }} color="textSecondary">
          A one-time authentication code has been sent to
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '13px', mt: 0.5, display: 'flex', justifyContent: 'center', fontWeight: 'bold' }} color="textSecondary">
          {submittedEmail}
        </Typography>
        <LoadingButton fullWidth size="large" variant="contained" onClick={handleWelcomeOpen} sx={{ mt: 3 }} disabled={!formik.isValid}>
          Verify
        </LoadingButton>
        <Typography variant="body1" sx={{ fontSize: '13px', mt: 2.7, display: 'flex', justifyContent: 'center' }} color="textSecondary">
          Expires in
          <Typography variant="body1" sx={{ fontSize: '13px', ml: 0.5 }} color="textSecondary">{`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</Typography>
          <Link variant="body1" sx={{ fontSize: '13px', ml: 0.5, cursor: 'pointer' }} color="info.main">Resend code</Link>
        </Typography>
      </Form>
    </FormikProvider>
  );
}


