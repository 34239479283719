import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { Icon } from '@iconify/react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container, Stack, Typography, ListItemAvatar, ListItem, ListItemText, ListItemIcon, Divider, Paper, Tooltip, Card, CardHeader, Avatar } from '@material-ui/core';
// hooks

// components

import closeFill from '@iconify/icons-eva/close-fill';
//

import { AccountBalanceWallet, AccountCircle, ArrowBack } from '@material-ui/icons';
import MenuPopover from 'src/components/MenuPopover';
import Scrollbar from 'src/components/Scrollbar';
import { useEffect, useRef, useState } from 'react';
import HomePageCartItem from 'src/pages/Joy-home-page/Home-page-cart-item-list/home-page-cart-item-list';
import Label from 'src/components/Label';
import JoyLoginPopUp from 'src/pages/Joy-home-page/Joy-login-popup/joy-login-popup';
import { PATH_AUTH, PATH_PAGE } from 'src/routes/paths';
import { MIconButton } from 'src/components/@material-extend';
import Logo from 'src/components/Logo';
import useOffSetTop from 'src/hooks/useOffSetTop';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import IconButton from 'src/theme/overrides/IconButton';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ITEM_HEIGHT = 'fit-content';
const PADDING_ITEM = 1.5;
const DRAWER_WIDTH = 350;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

const menuItems = [
  { id:1, icon: <Box sx={{ fontSize: '24px' }} className="material-symbols--person-outline"/>, text: "Profile" },
  { id:2, icon: <Box sx={{ fontSize: '24px' }} className="material-symbols--help"/>, text: "Help Center" },
];

// ----------------------------------------------------------------------

export default function CreateItemNavbar() {
  const navigate = useNavigate();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const isHome = pathname === '/create-item';

  const [openLogin, setOpenLogin] = useState(false);

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleOpenProfile = () => {
    navigate(PATH_PAGE.profileUser)
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [openCart, setOpenCart] = useState(false);

  useEffect(() => {
    if (openCart) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openCart]);

  const handleToggle = () => {
    setOpenCart((prev) => !prev);
  };

  const handleCartClose = () => {
    setOpenCart(false);
  };

  return (
    <AppBar color={isHome ? 'transparent' : 'default'} sx={{ boxShadow: 0,backdropFilter:'blur(20px)', }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/create-item">
            <MIconButton sx={{backgroundColor: { md: 'rgba(145, 158, 171, 0.16)', xs: 'transparent',height:'40px',width:'40px' },
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: { md: 'rgba(255, 255, 255, 0.3)', xs: 'transparent' },
                  },}}>
                <ArrowBack sx={{fontSize:'20px'}}/>
            </MIconButton>
          </RouterLink>
          <Typography variant='h5' color="common.white" sx={{ ml: 1.4,mr:2,display:{ md: 'block', sm: 'block', xs: 'block' }, }}>
            Create an AI Model
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Stack sx={{display:'flex',flexDirection:'row',gap:1}}>

            <Box sx={{ display: { xs: 'none', sm: 'block' }, gap: 1 }}>
            <Button 
              sx={{
                backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', xs: 'transparent' },
                color: '#fff',
                '&:hover': {
                  backgroundColor: { md: 'rgba(255, 255, 255, 0.3)', xs: 'transparent' },
                },
                borderRadius: '12px',
                height: '48px',
                minWidth: '48px',
                position: 'relative',
                padding: '0px 12px',
                backdropFilter: 'blur(20px)',
                transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                alignItems: 'center', 
                justifyContent: 'center', 
              }}
              // onClick={handleOpen}
              // ref={anchorRef}
            >
              <Box sx={{mt:0.5,mr:1}}>
                <Box className='material-symbols--wallet' sx={{fontSize:'1.5rem'}}/>
              </Box>
              <Typography>0 ETH</Typography>
              <Divider orientation="vertical" sx={{ my: 10, mx: 'auto', width: 5, height: 23,ml:1,mr:1.4,display:{ md: 'block', sm: 'none', xs: 'none' }, }} />
              <Typography>0 MATIC</Typography>
            </Button>
            </Box>
              <Button 
                sx={{
                  backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', xs: 'transparent' },
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: { md: 'rgba(255, 255, 255, 0.3)', xs: 'transparent' },
                  },
                  borderRadius: '12px',
                  height: '48px',
                  minWidth: '48px',
                  position: 'relative',
                  padding: '0px 12px',
                  backdropFilter: 'blur(20px)',
                  transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                  alignItems: 'center', 
                  justifyContent: 'center', 
                }}
                onClick={handleOpenProfile}
                // ref={anchorRef}
              >
                <Avatar src='https://i.pinimg.com/originals/52/91/4b/52914b8ac2f16a11c42786c3d89a84f5.gif' alt='Profile' sx={{width:'40px',height:'40px'}}/>
              </Button>
          
          </Stack>

          <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 260, }}>
            <Scrollbar sx={{ height: ITEM_HEIGHT * 8, paddingLeft: 2, paddingRight: 2 }}>
              {menuItems.map((item, index) => (
                <>
                  <ListItem key={item.id} button disableGutters sx={{ px: PADDING_ITEM, height: ITEM_HEIGHT, mt: 1,mb:1 , borderRadius: '10px' }}>
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ typography: 'subtitle2', mb: 0.5, fontSize: '17px', mt: 0.5 }}
                      secondaryTypographyProps={{ typography: 'caption' }}
                      primary={item.text}
                    />
                  </ListItem>
                  {/* {item.id === 2 && (<Divider sx={{mt:2.5}}/>)}
                  {item.id === 4 && (<Divider sx={{mt:2.5}}/>)} */}
                </>
              ))}
            </Scrollbar>
          </MenuPopover>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}

